<template>
  <div class="csn-withdrawal-history">
    <div class="row-fluid">
      <div class="casino-header mb-3 mt-3">
        <h1>{{ t('title') }}</h1>
        <hr />
        <p>{{ t('description') }}</p>
      </div>
    </div>

    <div class="casino-homepage-game">
      <div class="card-deck mb-3 text-center">
        <div class="row">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{{ t('currency') }}</th>
                <th scope="col">{{ t('amount') }}</th>
                <th scope="col">{{ t('created') }}</th>
                <th scope="col">{{ t('state') }}</th>
                <th scope="col">{{ t('action') }}</th>
              </tr>
            </thead>
            <tbody>
              <!-- {% for withdrawal in list.items %}
              <tr>
                <th scope="row">{{ loop.index }}</th>
                <th scope="row">{{ withdrawal.currency }}</th>
                <th scope="row">{{ withdrawal.amount }}</th>
                <th scope="row">{{ withdrawal.createdAt }}</th>
                <th scope="row">{{ statusLangList[withdrawal.state] }}</th>
                <th scope="row">
                  {% if withdrawal.state in allowCancelStatus %}
                  <a
                    href="{{ path('withdrawal_cancel', { paymentUuid: withdrawal.uuid }) }}"
                  >
                    <button class="btn btn-info">
                      {% trans %}withdrawal.history.button.cancel{% endtrans %}
                    </button>
                  </a>
                  {% endif %}
                </th>
              </tr>
              {% endfor %} -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WITHDRAWAL_HISTORY } from '@/constants'

export default {
  name: WITHDRAWAL_HISTORY,
  computed: {
    t() {
      return this.$createComponentTranslator(WITHDRAWAL_HISTORY)
    },
  },
}
</script>
